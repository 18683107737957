@import "assets/styles/colors.scss";

.userListContainer {
  padding: 20px;

  .onClickName {
    cursor: pointer;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .eyeIcon {
    color: $primary-text-color;
    cursor: pointer;
  }

  .amount {
    p {
      margin: 0px;
    }
  }

  :global(.ant-table-title) {
    padding: 0px 20px;
  }

  .customerTable {
    margin: 10px 0px;

     
  }

  .searchBoxContainer {
    padding: 10px 20px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 20px;

    img {
      cursor: pointer;
      width: 15px;
    }

    div {
      display: flex;
      flex-direction: row;
      
      gap: 5px;

      button {
        padding: 5px 10px;
        font-size: 10px;
        color: $secondary-btn-color;
        background-color: $secondary-btn-background-color;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .searchBoxContainer {
      input {
        width: 250px !important;
      }
    }
  }
}

.modalHeading {
  


  .btn {
    min-width: 100%;
  }
}


.modalEmployee {
  width: 650px;
}