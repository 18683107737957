@import "assets/styles/colors.scss";

.auctionDetailsContainer {
  margin: 20px 40px;

  h5, h2, h4, p {
    font-size: 15px;
    margin: 0;
  }

  .topBtnContainer {
    gap: 20px;
    button {
      padding: 20px;
    }
  }

  .boxContainer {
    border: 2px solid $primary-border-color;
    border-radius: 5px;
    position: relative;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .imgLength {
      background-color: $primary-heading-color;
      position: absolute;
      right: 15%;
      bottom: 25px;
      width: 40px;
      height: 40px;
      color: white;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      p {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
      }
    }

    img {
      width: 80%;
      height: auto;
    }

    .textBoxContainer {
      width: 100%;
      :global(.ant-tabs-tab) {
        margin-right: 20px;
        color: $primary-heading-color;
        font-weight: bold;
      }
      b {
        color: $primary-heading-color;
        font-weight: bolder;
        font-size: 15px;
      }
      p {
        color: $primary-text-color;
        margin: 0;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }

  .rightCol {
    padding: 0px 25px !important;

    .maxBidRow {
      margin: 20px 0px;
      padding: 20px;
      border: 2px solid $primary-border-color;

      h4 {
        color: $primary-heading-color;
        font-weight: bold;
        font-size: 15px;
        margin: 0;
      }

      p {
        margin: 0;
        color: $primary-text-color;
        font-size: 15px;
        font-weight: bold;
      }

      .seeUserDetails {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .headingRow {
      display: flex;
      margin-top: 25px;
      h2 {
        margin: 0;
        color: $primary-heading-color;
        font-weight: bolder;
        font-size: 15px;
      }
      button {
        float: right;
        margin-top: 5px;
      }
    }

    .auctionRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: $primary-text-color;
      margin: 10px 0px;

      h6 {
        font-weight: bolder;
        color: $primary-heading-color;
        font-size: 15px;
      }
      p {
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 15px;

        span {
        color: black;
                font-weight: normal;

        }
      }
    }

    .cardsAuctionRow {
      font-weight: bolder;
      border: 2px solid $primary-border-color;
      padding: 20px;
      p {
        color: $primary-text-color;
        font-size: 15px;
        font-weight: bold;
      }
      h5 {
        color: black;
        font-size: 15px;
      }
    }

    .bidAmount {
      border: 2px solid $primary-border-color;
      margin: 20px 0px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      p {
        color: $primary-text-color;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        font-size: 15px;
        font-weight: bold;

        span {
          color: black;
          font-weight: normal;
        }
      }

      h5 {
        color: $primary-heading-color;
        font-size: 15px;
        font-weight: bold;
      }
    }

    .allBidCard {
      border: 2px solid $primary-border-color;
      margin: 20px 0px;
      padding: 20px;
      flex-direction: column;

      .bidHeadingRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h4 {
          color: $primary-heading-color;
          font-weight: bold;
          font-size: 15px;
        }

        h6 {
          color: $primary-heading-color;
          font-weight: bold;
          font-size: 15px;
        }
      }
    }

    .bidListRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 2px solid $primary-border-color;
      margin: 10px 0px;
      padding: 20px;

      h4 {
        color: $primary-heading-color;
        font-weight: bold;
        margin: 0;
      }

      h6 {
        color: $primary-heading-color;
        font-weight: bold;
        margin: 0;
      }
    }

    .auctionHistory {
      flex-direction: column;
      padding: 20px;

      h2 {
        color: $primary-heading-color;
        font-size: 15px;
        font-weight: bold;
      }

      p {
        color: $primary-text-color;
        font-size: 15px;
        b {
          color: $primary-heading-color;
          font-weight: bold;
          font-size: 15px;
        }
      }
      b {
        color: $primary-heading-color;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        margin: 0;
      }

      .rightHistoyCol {
        text-align: right;

        p {
          margin: 0;
          font-size: 15px;
          font-weight: bold;
        }
      }

      .leftHistoyCol {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          font-size: 15px;
          color: $primary-text-color;
        }
      }
    }
  }
}

.modalCancelAuction {
  width: 400px;

  p {
    color: $primary-text-color;
    margin: 10px 0px;
    font-size: 15px;
    font-weight: bold;
  }

  .reasonInputContainer {
    margin: 20px 0px;
    position: relative;

    span {
      position: absolute;
      right: 20px;
      top: 35px;
      z-index: 2;
      color: $primary-text-color;
    }
  }

  .btn {
    width: 100%;
    margin: 10px 0px;
  }

  :global(.ant-modal-title) {
    margin-top: 3px;
  }
}

.winnerModal {
  width: 400px;

  h5,
  h2,
  h4 {
    font-size: 15px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
    color: black;
  }
}
