@import "assets/styles/colors.scss";

.dashboardContaienr {
  padding: 0px 25px;

  .statusRow {
    margin: 20px 0px;

    .statusColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 10px 0;

      .statusCardContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        background-color: transparent;
        border: 2px solid $secondary-card-border-color;
        background-color: $secondary-card-bg;
        border-radius: 10px;

        .iconImgContainer {
          img {
            height: 50px;
            width: 50px;
          }

          .noImage {
            width: 60px;
            height: 60px;
          }
        }

        .leftBoxStatus {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          p {
            margin: 0;
            font-weight: bold;
            color: black;
            font-size: 17px;
          }
          span {
            font-weight: 900;
            color: $primary-number-color;
            font-size: 20px;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .statusCardContainer {
        .leftBoxStatus {
          width: 60%;
          flex-direction: row !important;
          justify-content: space-between;
        }
      }
    }

    @media screen and (max-width: 500px) {
      .statusCardContainer {
        .leftBoxStatus {
          width: auto;
          flex-direction: column !important;
        }
      }
    }
  }

  .recentAuctions {
    background-color: $secondary-card-bg;
    padding: 0px 20px;
    padding-top: 10px;
    border-radius: 10px;
    padding-bottom: 20px;

    .auctionCards {
      background-color: $pimary-page-bg;
      padding: 10px;
      border-radius: 10px;
      margin: 10px 0px;

      .leftBox {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;

        .noImage {
          width: 60px;
          height: 60px;
        }

        img {
          width: 80px;
          height: 80px;
        }

        .titleContainer {
          display: flex;
          flex-direction: column;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          h5,
          p {
            margin: 0;
            font-weight: bold;
          }
          h5 {
            color: $primary-heading-color;
            font-size: 15px;
            font-weight: bold;
          }
          p {
            color: $primary-text-color;
            font-size: 12px;
          }
        }
      }

      .rightBox {
        .noOfBids {
          margin: 0;
          text-align: right;
          color: $primary-number-color;

          span {
            font-weight: bold;
            color: $primary-number-color;
          }
        }

        .status {
          margin: 0;
          text-align: right;
          span {
            color: #ae2c2c;
            text-transform: capitalize;
          }
        }
      }

      .tableAuctionsContainer {
        overflow: auto;
        margin-top: 10px;

        .tableAuctions {
          width: 100%;
          font-weight: bold;
          td {
            color: $primary-text-color;
          }
        }

        .amount {
          p {
            margin: 0px;
          }
        }
      }

      @media screen and (max-width: 577px) {
        .leftBox {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin: 10px 0px;
        }

        .rightBox {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin: 10px 0px;
          p {
            text-align: center;
          }
        }
      }
    }
  }

  .recentWinners {
    background-color: $secondary-card-bg;
    padding: 10px 20px;
    border-radius: 10px;

    .recentCards {
      border-radius: 4px;
      padding: 10px 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      background-color: $pimary-page-bg;

      .winnerCardLeft {
        display: flex;
        flex-direction: column;
        h5,
        p {
          margin: 0;
          font-weight: bold;
        }
        h5 {
          color: $primary-heading-color;
          font-size: 17px;
          font-weight: bold;
        }
        p {
          color: $primary-text-color;
          font-size: 14px;
        }

        .winnerAuctionId {
          cursor: pointer !important ;
          &:hover {
            text-decoration: underline !important;
          }
        }
      }

      .winnerCardRight {
        display: flex;
        flex-direction: column;

        h5,
        p {
          margin: 0;
          font-weight: bold;
          color: $primary-text-color;
          text-align: right;
          font-size: 14px;
        }
      }
    }
  }
}
